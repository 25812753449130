<!--没有用起来-->
<template>
  <div class="main" v-title  data-title="中欧家庭教育">
     <p class="tip">提示：长按图片识别图中的二维码可进入直播间</p>
     <img src="../assets/images/haibao.png"/>
  </div>
</template>

<script>

// import html2canvas from 'html2canvas';
// import Canvas2Image from 'canvas2image';

export default {
  components: {
    // Canvas2Image,
    // html2canvas
  },
  data() {
    return {}
  },
  methods: {
    // convert2canvas() {
    //   // 获取需要转化的dom对象 直接使用$('.wrap')选取的为jquery对象 无法继续操作
    //   var cntElem = $('.wrap')[0];
    //
    //   var shareContent = cntElem; //需要截图的包裹的（原生的）DOM 对象
    //   var width = shareContent.offsetWidth; //获取dom 宽度
    //   var height = shareContent.offsetHeight; //获取dom 高度
    //
    //   var canvas = document.createElement("canvas"); //创建一个canvas节点
    //   var scale = 2; //定义任意放大倍数 支持小数
    //   canvas.width = width * scale; //定义canvas 宽度 * 缩放
    //   canvas.height = height * scale; //定义canvas高度 *缩放
    //   //放大后再缩小提高清晰度
    //   canvas.getContext("2d").scale(scale, scale);
    //
    //   console.log(width)
    //   console.log(height)
    //   // 设置html2canvas方法的配置
    //   var opts = {
    //     scale: scale, // 添加的scale 参数
    //     canvas: canvas, //自定义 canvas
    //     // allowTaint: true, //允许画布上有跨域图片 不建议使用 后面详细补充
    //     // logging: true, //日志开关，便于查看html2canvas的内部执行流程
    //     width: width, //dom 原始宽度
    //     height: height,
    //     useCORS: true // 【重要】开启跨域配置
    //   };
    //   // 开始转化为canvs对象
    //   html2canvas(shareContent, opts).then(function (canvas) {
    //
    //     var context = canvas.getContext('2d');
    //     // 【重要】关闭抗锯齿
    //     context.mozImageSmoothingEnabled = false;
    //     context.webkitImageSmoothingEnabled = false;
    //     context.msImageSmoothingEnabled = false;
    //     context.imageSmoothingEnabled = false;
    //
    //     // 【重要】默认转化的格式为png,也可设置为其他格式
    //     var img = Canvas2Image.convertToJPEG(canvas, canvas.width, canvas.height);
    //     //转化后放哪 最好放在与 .wrap 父级下
    //     var detail = document.getElementsByName(".wrap");
    //     detail.appendChild(img);
    //     // 最后设置img标签为正常高度宽度 提高清晰度
    //     $(img).css({
    //       "width": canvas.width / 2 + "px",
    //       "height": canvas.height / 2 + "px",
    //     }).addClass('f-full');
    //
    //   });
    // }

  },
  mounted() {
    // this.convert2canvas();
  }
}
</script>
<style lang="less">

.main {
  height: auto;
  width: 100%;
}

img {
  width: 100%;
  //max-width: 100%;
}

.tip {
  padding-top:20px;
  padding-bottom: 20px;
  font-size: 16px;
}
</style>
